*{
 
  padding:  0 ;
  margin : 0 ; 
  box-sizing:  border-box;  
 
}





.moduleContentType{

height: 100vh ;
width : 100vw ; 
display:  flex ;
background-color: #F1F2F7;


}  


.moduleContentType-sidebar{


  
width : 19% ; 
height : 100% ; 
 background-color: #353B55 ; 

} 

.moduleContentType-body{

 
  display: flex;
  flex-direction: column;
  width : 81% ; 
  height :  100%; 
 background-color: #F1F2F7 ;  
  justify-content:  center;
  align-items: center;

}   

.moduleContentType-btn{
  display: flex;
  justify-content:  center;
  align-items: center; 

}