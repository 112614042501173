.popup{
  
    position : fixed ; 
    right : 0 ;
    top : 0 ; 
    width: 81%; 
    height : 100vh ; 
    display: flex;
    align-items:  center; 
    justify-content: center;   
    background-color: rgba(128, 128, 128, 0.6);
 

 
} 



.popup-inner{

position: relative;
width : 50% ; 
height : 40% ;

background-color: #353B55;   
border-radius: 2%;



}  



 .close-btnn {
    
    height : 80% ;
    width : 10% ;   
    border : 0px
  

}  

.popup-inner-body1{

    height: 20%; 
    display : flex ; 
    align-items:  center ; 
    justify-content: flex-end ;  
    padding-right:  2%;
}


.popup-inner-body2{

  
      height: 40%; 
      background-color: #FFF;
      display:  flex;
      justify-content: center;
      align-items: center;
      flex-direction:  column;

} 
  



.popup-inner-body3{

  
    height: 40%; 
    display : flex ; 
    align-items:  center ; 
    justify-content: space-around;

}  





.popup-inner-body2-button{

   height : 45% ;
   width : 25% ; 
   background-color: #B7B7D1;


}

