.popup{
  
    position : fixed ; 
    right : 0 ;
    top : 0 ; 
    width: 81%; 
    height : 100vh ; 
    display: flex;
    align-items:  center; 
    justify-content: center;   
    background-color: rgba(128, 128, 128, 0.6);
 

 
} 



.course_popup-inner{

position: relative;
width : 40% ; 
height : 30% ;

background-color: #353B55;   
border-radius: 2%; 
display: flex; 
justify-content: center; 
flex-direction:  column;
align-items: center;



}  



 .close-btn {
    
    height : 60% ;
    width : 20% ;   
    background-color:  pink;
  

}  

.course_popup-inner-body1{

    height: 25%; 
    display : flex ; 
    align-items:  center ; 
    justify-content: flex-end ;  
    padding-right:  2%; 
    width : 100% ; 
}


.course_popup-inner-body2{

  
      height: 25%;  
      display: flex; 
      align-items:  center;
      justify-content: center;
   

}  




 .course_popup_text {

    font-weight: 600; 
    color: #FFFFFF;
 }



  



.course_popup-inner-body3{

  
    height: 50%;  
    width:  90%;
    display : flex ; 
    align-items:  center ; 
    justify-content: space-around; 

}  





.course_popup-inner-body2-button{

   height : 50% ;
   width : 30% ; 
   background-color: #B7B7D1;


}

