.passwordPopups{
  
    position : fixed ; 
    right : 0 ;
    top : 0 ; 
    width: 52.69%;
    height : 100vh ; 
    display: flex; 
    align-items: center; 
   

} 


.passwordPopups_inner{
    position: relative;
    width : 55% ; 
    height : 35% ;
    
    background-color: #353B55;   
    border-radius: 2%; 
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center;

    

}


