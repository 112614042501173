*{
 
    padding:  0 ;
    margin : 0 ; 
    box-sizing:  border-box;  
   
  }
  
  
  
  
  
  .form_outer_div{
  
  height: 100vh ;
  width : 100vw ; 
  display:  flex ;
  background-color:  #FFFFFF;  
  flex-direction: row;
  
  
  }  
  
  
  .form_outer_div_sidebar{
  
  
    
  width : 19% ; 
  height : 100% ; 
   background-color: #353B55 ; 
  
  } 
  
  .createcontent_form_outer_div_body{
  
   
    display: flex;
    flex-direction: column;
    width : 81% ; 
    height :  100%; 


  
  } 
   
  .createcontent_admin_form{
    
    margin-top:  5%;
    height : 52% ;
    width : 100%  ; 
 
    display: flex ; 
    flex-direction: column;  
    
  }  
   
  
  .createcontent_admin_form_row{
  
      width : 100% ;  
      height: 100%;
   
      display:  flex ;  

      flex-direction: row ;
 
      border-top:  1px  solid black   ;
  
  }  
  
  
  .admin_Form-Description{
  
    
    flex : 30% ;   
    display:flex ; 
    align-items: center; 
    padding-left: 5%;
  } 
  
  .createcontent_admin_Form-Input{
      
    flex : 70% ; 
  
   display: flex; 
   align-items:  center ;
   justify-content:center  ; 
   border-left:  1px solid black;
  
  }  
  
  
  .admin_input-box{
    


    width : 80% ; 
    height : 60% ;  
    border-radius:  20px;  


  
  } 


  .admin_form_row_btn_div{
    width : 100% ;  
    height: 100%;
    background-color:#FFFFFF; 
    display:  flex ;  

    flex-direction: row ;
    border:   1px solid black ; 
    align-items:  flex-end ; 
    padding-bottom:  1.5% ;
    justify-content: flex-end ; 
    padding-right: 4%;

  }

  .admin_form_row_btn{

     
    width : 10.96% ; 
    height : 45% ;  
    border-radius:  20px;  
    background-color: #FCC046; 
    border : 0px solid red;



  }  


  input[type="checkbox"] {
   
    border: 5px solid red;
  
    display: table-row;
  
  
  } 
  
  