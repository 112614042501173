
*{
 
    padding:  0 ;
    margin : 0 ; 
    box-sizing:  border-box;  
   
  }




.course{

 
    height: 100vh ;
    width : 100vw ; 
   display:  flex ;
   background-color:  #FFFFFF ;  
   


}



.course_sidebar{

      
    width : 19% ; 
    height : 100% ; 
     background-color: #353B55 ; 
} 



.course_body{

    display: flex;
    flex-direction: column;
    width : 81% ; 
    height :  100%; 
   background-color: #FFF;  
  
    align-items: center;

}
 

.course_body_inner_div{


    width: 53.89% ; 
    height: 37.90% ; 
    background-color: #FFF; 
    margin-top: 10%;  
    display: flex; 
    flex-direction: column;
    align-items:  center; 
    
}


.course_input-box{


    width : 100% ;  
    height: 26%;
    background-color:#FFF; 
    display:  flex ;  

    flex-direction: row ;
    border:   1px solid #5E82F4 ;  
    text-align: center;
    margin-bottom: 1.5%; 
    border-radius: 20px;

}

 
.course_form_row_btn{

    width : 20% ;  
    height: 13.62%; 
    border-radius: 30px;
    background-color: #FCC046; 
    border:  0px solid black;


}

