*{
 
    padding:  0 ;
    margin : 0 ; 
    box-sizing:  border-box;  
   
  }
  



 .firstview{

    height: 100vh ;
    width : 100vw ; 
   display:  flex ;
    background-color: orangered;
   
   
   }   


.firstview_sidebar{ 
  
  
    
    width : 19% ; 
    height : 100% ; 
     background-color: #FFF; 
  


} 

.firstview_body{ 
  

    display: flex;
    flex-direction: column;
    width : 81% ; 
    height :  100%; 
   background-color: grey ;  
    justify-content:  center;
    align-items: center;

} 


.body1{

    height:  8.06% ;
    width : 100%; 
   display:  flex ;
    background-color: #FFF; 
    flex-direction: column; 
    align-items: center; 
    justify-content: center;

}

.table_outer_div_body2{
   

    height:  67.12% ;
    width : 100%; 
   display:  flex ;
    background-color: orangered; 
    flex-direction: column;

}  


.table_inner_div_column_name{  



  
    height:  20% ;
    width : 100%;  
    background-color: #D9D9D9; 
    display: flex ; 
    overflow-y:  scroll ; 
}  


 


.table_inner_div_column_name::-webkit-scrollbar{

    width:  8px; 
    background-color: #B6B7D0 ;

}




.table_inner_div_table_row{
  
    height:  60% ;
    width : 100%;  
    background-color: #FFF;
    overflow-y:  scroll ; 

}   





.table_inner_div_table_row::-webkit-scrollbar{

    width:  8px; 
    background-color: #B6B7D0 ;

}






.body3{

     
    height:  24.82% ;
    width : 100%; 
   display:  flex ;
   background-color: #FFF;
    flex-direction: column; 
    align-items:  flex-end;
 
}
     




.inner_div_table_row_box{

     

    display: flex ; 
    align-items: center; 
    justify-content: center; 
   
} 
 


.inner_table_btn{

    background-color: #FCC046;
    border-radius: 20px; 
    border: 0px solid red ;
}



.add_new_program_button{
   margin-top:  2%;
    height:  24.82% ;
    width : 18%;  
    background-color: #FCC046 ; 
    margin-right: 5%;
    border-width: 0;
    border-radius:  25px ; 
    display: flex; 
    align-items: center; 
    justify-content: center;


} 



.header_text{


 font-weight:  700;
 font-size:  16px;
 
 font-style: normal;
}