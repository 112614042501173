*{
 
    padding:  0 ;
    margin : 0 ; 
    box-sizing:  border-box;  
   
  } 


  .editprogram_form_outer_div{
  
    height: 100vh ;
    width : 100vw ; 
    display:  flex ;
    background-color:   green   ;  
    flex-direction: row;
    
    
    }   




  .editprogram_form_outer_div_sidebar{
         
  width : 19% ; 
  height : 100% ; 
   background-color: #353B55 ;    


    } 
 



    .editprogram_form_outer_div_body{

      display: flex;
      flex-direction: column;
      width : 81% ; 
      height :  100%; 
     background-color:   #FFFFFF;  
  

    }  



    .editprogram_admin_form{
    
      
      margin-top:  5%;
      height : 52% ;
      width : 100%  ; 
    
      display: flex ; 
      flex-direction: column;  
      
      

    } 
  



      
  .editprogram_admin_form_row{
  
    width : 100% ;  
    height: 100%;
 
    display:  flex ;  

    flex-direction: row ;

    border-top:  1px  solid #B6B7D0   ;

}  
     



.editprogram_admin_Form-Input{
      
  flex : 70% ; 

 display: flex; 
 align-items:  center ;
 justify-content:center  ; 
 border-left:  1px solid #B6B7D0;

}  

.editaccount_form_row{


  width : 100% ;  
  height: 100%;

  display:  flex ;  

  flex-direction: row ;
  border:   1px solid #B6B7D0 ;
 


}


 


.editaccount_form_row_btn_div{

    
  width : 100% ;  
  height: 100%;

  display:  flex ;  

  flex-direction: row ;

  align-items:  flex-end ; 
  padding-bottom:  1.5% ;
  justify-content: flex-end ; 
  padding-right: 4%;


}  

.editaccount_form_row_btn{
    
  width : 10.96% ; 
  height : 55% ;  
  border-radius:  20px;  
  background-color: #FCC046;
  border : 0px ;  
  margin-left: 2%;


} 




.admin_Form-Description{



  flex : 30% ;   
  display:flex ; 
  align-items: center; 
  padding-left: 5%;
  
  } 
  
  .admin_Form-Input{
      
    flex : 70% ; 
   background-color: #FFFFFF ; 
   display: flex; 
   align-items:  center ;
   justify-content:center  ;
   border-left: 1px solid #B6B7D0 ;
  
  }  
  
  
  .admin_input-box{
  
    width : 80% ; 
    height : 60% ;  
    border-radius:  20px;
    border : 1px solid #5E82F4 ;
  }
  
  
  
  
  




