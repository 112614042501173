*{
 
    padding:  0 ;
    margin : 0 ; 
    box-sizing:  border-box;  
   
  }

  
  .upload{

height: 100vh ;
width : 100vw ; 
display:  flex ;
background-color: #FFF8EE;
  }  

 


.upload-sidebar{

    
width : 19% ; 
height : 100% ; 
 background-color: #353B55 ; 


}
 

.upload-body{


 
    display: flex;
    flex-direction: column;
    width : 81% ; 
    height :  100%; 
   background-color: #FFF8EE;   
   display: flex; 
   align-items: center;

  

}   




.upload-body-div1{
    width : 100% ; 
    height :  22.04%;  
    background-color: #FFF8EE ;  
    display: flex; 
    align-items: center; 
    justify-content: center;
   
}

.upload-body-div2{

    width : 63.61% ; 
    height :  40.80%;   
    background-color:  #FFF8EE ; 
    display: flex;  
    flex-direction: column; 
    justify-content: space-between ; 
    align-items: flex-end;

}
  




.upload-body-div3{
   
    width : 63.61% ; 
    height :  10%;   
    background-color: #FFF8EE; 
    display :flex ;
    align-items : flex-end ;
    justify-content : flex-end ; 


}



.upload-body-div2-inner-div1{


    width : 100% ;
     height : 35.18%  ;  
    background-color: #D9D9D9;
     display: flex; 
     flex-direction: row;
    
} 


.upload-body-div2-inner-div2{


    width : 100% ;
     height : 31.79%  ;  
     background-color: #FFF8EE; 

     display: flex; 
     flex-direction: row;
    
    
}


.upload-body-div1-input-box{

   
    width : 53.99% ; 
    height :  44.57%;     
    border-radius:  20px; 
    text-align : center ;


}
 

.upload-body-input-box{

    width : 90% ; 
    height :  75.72%;   
    
} 


.upload-body-input-checkbox_outer_div{

    width : 10% ; 
    height :  75.72%; 
    background-color: aquamarine;

} 


 
.upload-body-box{


    display:flex; 
    align-items: center; 
    justify-content: center;
} 


.upload_file{
    
    width : 100% ; 
    height :  98%;   
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; 

} 
 




img{

    object-fit: contain;
}
