*{
 
    padding:  0 ;
    margin : 0 ; 
    box-sizing:  border-box;  
   
  }
   




.answer_main_div{
 

  background-color: #FFF; 
   height: 100vh ;
   width : 100vw ;  
   display:  flex ;
  } 



  .answer_main_div_sidebar{
  
  
    
    width : 19% ; 
    height : 100% ; 
     background-color: #353B55 ;  
     border-radius:  25;
    
    } 
    
    .answer_main_div_body{
    
     
      display: flex;
      flex-direction: column;
      width : 81% ; 
      height :  100%; 
      background-color: #FFF;
  
    
    }    


     
    .answer_inner_div_1 {
    
     
      width : 100% ; 
      height :  8%; 
      background-color: #FFF;
      display: flex; 
      flex-direction: row;
    
    }    


    .answer_inner_div_2 {
    
     
      width : 100% ; 
      height :  82%; 
      background-color: bisque;
      overflow-y:  scroll ; 
    
    }    




    .answer_inner_div_2::-webkit-scrollbar{

      width:  8px; 
      background-color: #B6B7D0 ; 
  
  
  } 
  



  .answer_inner_div_column_name{
  
    height:  20% ;
    width : 100%;  
    background-color:#D9D9D9; 
    display: flex ;   
    overflow-y:  scroll ; 
     border-bottom : 1px solid #B6B7D0 ; 
     border-top : 1px solid #B6B7D0 ; 
}    


 
.answer_inner_div_column_name::-webkit-scrollbar{

  width:  4px; 
  background-color: #B6B7D0 ;

}
 




.answer_inner_div_table_row{
  
  height:  80% ;
  width : 100%;  
  background-color:#FFF;
  overflow-y:  scroll ; 

} 



.answer_inner_div_table_row::-webkit-scrollbar{

  width:  5px; 
  background-color:  #B6B7D0 ;  
 


} 

.answer_inner_div_table_row::-webkit-scrollbar-thumb {
  background-color: green;
}

.answer_inner_div_table_row::-webkit-scrollbar-thumb:hover {
  background-color: #555; 
  width: 3px;
}


.answer_table_row_box{

  display: flex ; 
  align-items: center; 
  justify-content: center; 
}
 

.answer_table_row_box_scrollable {

  padding :2px ;  
  overflow-y:  scroll ; 


}



.answer_table_row_box_scrollable::-webkit-scrollbar{

  width:  2px; 
  background-color:  #B6B7D0 ;  
 


} 


  



