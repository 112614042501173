*{
 
    padding:  0 ;
    margin : 0 ; 
    box-sizing:  border-box;  
   
  }
  





.secondview{

    height: 100vh ;
    width : 100vw ; 
   display:  flex ;
   background-color: #FFF8EE ;  

}


.secondview_sidebar{


   
    width : 19% ; 
    height : 100% ; 
     background-color: #353B55 ; 
  

} 


.secondview_body{

  
    display: flex;
    flex-direction: column;
    width : 81% ; 
    height :  100%; 
   background-color: #FFF8EE ;  
   align-items:  center;
  


} 



.secondview_body_innner_div1{

    width : 69.13% ; 
    height :  13.60% ; 
    background-color: #FFF8EE;  
    margin-top: 14.60%; 
    display: flex;
  justify-content: space-between;

}


.secondview_body_innner_tab{

    width : 42.15% ; 
    height :  100% ; 
    background-color:#B7B7D1; 
    border-radius: 25px; 
    display: flex;
    align-items: center;
    justify-content: center;
    

}